import { useQuery } from '@tanstack/vue-query'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { StoreOrOutletSearchResult } from '~/types'

export const useStoresOrOutletsStore = defineStore('storesoroutlets', () => {
  const searchTerm = ref<string>()
  const searchStore = useSearchStore()
  const user = useUserStore()

  const query = computed(() => ({
    searchTerm: searchTerm.value,
    longSw: searchStore.query.longSw,
    latSw: searchStore.query.latSw,
    longNe: searchStore.query.longNe,
    latNe: searchStore.query.latNe
  }))

  const { data, status, isFetching } = useQuery({
    queryKey: ['storeoroutlets', 'list', searchTerm],
    queryFn: () =>
      $fetch<Array<StoreOrOutletSearchResult>>('/api/storeoroutlets', {
        query: query.value
      }),
    select: data => {
      return data.sort((a, b) => {
        if (a.country === b.country) return 0
        if (a.country === user.countryName) return -1
        if (b.country === user.countryName) return 1
        return 0
      })
    },
    // persister: experimental_createPersister({
    //   storage: localStorage,
    //   maxAge: 10000
    // }),
    enabled: () => searchTerm.value !== undefined,
    staleTime: 30000 // 30 seconds
  })

  // actions
  function search(term: string) {
    searchTerm.value = term
  }

  return {
    // state
    pending: isFetching,
    results: computed(() => data.value ?? []),
    searchTerm: computed(() => searchTerm.value),
    status,

    // getters
    query,

    // actions
    search
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoresOrOutletsStore, import.meta.hot))
}
