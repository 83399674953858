<template>
  <div ref="hoveredPopoverRef" role="tooltip" id="map-popover-hovered" class="w-[264px] z-20 bg-transparent">
    <UCard
      v-if="store"
      :ui="{
        base: 'border shadow-lg',
        ring: '',
        divide: '',
        header: {
          padding: 'p-4 sm:p-4'
        },
        body: {
          padding: 'p-0 sm:p-0'
        },
        footer: {
          base: 'border-t-2 border-trublue-500',
          padding: 'py-2 px-4 sm:px-4'
        }
      }"
    >
      <template #header>
        <h3 class="text-sm font-bold">
          {{ store.displayName }}
        </h3>
        <h4 class="mt-2 text-xs">
          {{ addressConcatenated }}
        </h4>
      </template>
      <template #footer>
        <div class="flex items-center gap-x-1 gap-y-2">
          <img src="@/assets/images/trubutton.svg" alt="trubutton" width="56" height="48" class="w-10" />
          <UiStarRatings
            :rating="parseFloat(store.truRating ?? '0')"
            :total-ratings="Number(store.ratingCount)"
            size="small"
            variant="purple"
          />
        </div>
        <div v-if="store.profileUrl" class="flex justify-center mt-4">
          <UButton
            :to="store.profileUrl"
            variant="outline"
            size="xs"
            :ui="{
              rounded: 'rounded-2xl'
            }"
            class="text-trublue-700"
          >
            More
            <template #trailing>
              <font-awesome-icon icon="fa-solid fa-chevron-right" class="text-trublue-700" />
            </template>
          </UButton>
        </div>
      </template>
    </UCard>
  </div>
  <!-- <div
    class="relative w-0 h-0 border-t-[16px] border-x-[16px] -mt-1 translate-x-24 border-x-transparent border-t-white"
  ></div> -->
</template>

<script setup lang="ts">
import type { StoreResult } from '@/types'
const hoveredPopoverRef = ref()

const { elementX, isOutside } = useMouseInElement(hoveredPopoverRef, { handleOutside: false })

const props = defineProps<{
  store: StoreResult | undefined
  location?: { left: number; top: number }
}>()
const emit = defineEmits<{ close: [boolean]; isOutside: [boolean] }>()

let addressConcatenated = ''
if (props.store) {
  const { town, county, postCode } = props.store
  addressConcatenated = [town, county, postCode].filter(Boolean).join(', ')
}

watchThrottled(
  elementX,
  () => {
    emit('isOutside', isOutside.value)
  },
  { throttle: 250 }
)

watch(isOutside, val => {
  const timeout = setTimeout(() => {
    emit('isOutside', val)
    clearTimeout(timeout)
  }, 250)
})
</script>
