<template>
  <div class="relative flex items-center justify-between h-full">
    <div
      v-if="autocompleteHasFocus && user.permissionStatus === 'denied'"
      class="absolute -ml-4 lg:ml-0 w-[calc(100%+5rem)] lg:w-[calc(100%)] text-sm top-10 z-50"
    >
      <div
        @click="showUnableToUseLocationMessage = !showUnableToUseLocationMessage"
        class="flex items-center justify-between font-semibold p-2.5 bg-pink-100 text-product"
      >
        <div>
          <Icon name="mdi:navigation-variant-outline" aria-label="Use My Location" size="20px" />
          Use My Location
        </div>
        <Icon name="mdi:information-outline" aria-label="Information" size="20px" class="hover:cursor-pointer" />
      </div>
      <div
        v-show="showUnableToUseLocationMessage"
        class="relative -top-2 flex flex-col font-medium translate-x-4 text-purple"
      >
        <!-- ← triangle container -->
        <div class="-mb-[1px] ml-2 inline-block overflow-hidden">
          <!-- triangle ↓ -->
          <div class="w-4 h-4 origin-bottom-left transform rotate-45 bg-white border border-gray-300"></div>
        </div>
        <div class="flex flex-col p-4 bg-white border border-gray-300 rounded-md shadow-md max-w-44 lg:max-w-96">
          Sorry, we are unable to use your location as you didn’t consent to sharing this with us. You can change this
          by updating the location settings in your web browser
        </div>
      </div>
    </div>

    <button
      @click="clear"
      v-if="user.useMyLocation"
      type="button"
      class="absolute inset-0 z-10 flex items-center w-full text-sm bg-white"
      variant="link"
    >
      <img src="/images/my_location.svg" alt="My Location" class="w-4 h-4 mr-2" />
      My Location
    </button>
    <AutoComplete
      ref="locationAutoComplete"
      v-model="geocoded.searchTermModel"
      :suggestions="geocoded.suggestions"
      :delay="300"
      :loading="geocoded.pending"
      :min-length="2"
      :placeholder="isMobile ? 'Location' : 'Location or postal code'"
      @complete="$event => performSearch($event.query)"
      @item-select="onSelected"
      @focus="onFocus"
      @blur="autocompleteHasFocus = true"
      option-label="value"
      option-value="id"
      dropdown
      :pt="pt"
    >
      <template #header>
        <UButton
          v-if="user.permissionStatus !== 'denied'"
          block
          variant="ghost"
          class="justify-start pt-4 pb-2 font-semibold text-trublue-700"
          @click="getUserLocation"
        >
          <template #leading>
            <Icon name="mdi:navigation-variant-outline" aria-multiline="Use My Location" size="20px" />
          </template>
          Use my location</UButton
        >
      </template>
      <template #loadingicon>
        <div v-show="geocoded.pending" class="loader"></div>
      </template>
    </AutoComplete>
    <UButton
      v-show="user.useMyLocation || (geocoded.searchTermModel && !geocoded.pending)"
      variant="ghost"
      class="absolute right-0 z-20 hidden -mt-4 text-gray-500 md:block top-1/2"
      @click="clear"
      >X</UButton
    >
  </div>
</template>

<script setup lang="ts">
import type { AutoCompleteItemSelectEvent, AutoCompletePassThroughOptions } from 'primevue/autocomplete'
import type { PassThrough } from 'primevue/ts-helpers'
import type { GeoCoderSearchResult } from '~/types'
const { isMobile } = useDevice()

const locationAutoComplete = ref()
const autocompleteHasFocus = ref(false)
const showUnableToUseLocationMessage = ref(false)

const config = useRuntimeConfig()
const map = useMapStore()
const geocoded = useGeocodedStore()
const search = useSearchStore()
const ui = useUiStore()
const user = useUserStore()
const gtm = useGtm()
const gtag = useGtag()

function onFocus() {
  locationAutoComplete.value.show()
  autocompleteHasFocus.value = true
  if (search.location) {
    clear()
  }
}

async function performSearch(locationOrPostalCode: string) {
  if (locationOrPostalCode.length < 2) return
  gtm?.trackEvent({
    event: 'Search by location',
    category: 'search',
    action: 'User searched by location',
    label: locationOrPostalCode
  })
  geocoded.fetchSuggestions(locationOrPostalCode)
}

function clear() {
  geocoded.clear()
  locationAutoComplete.value.show(true)
}

async function onSelected({ value }: AutoCompleteItemSelectEvent) {
  const location = value as GeoCoderSearchResult
  await geocoded.fetchGeocodedPlace(location.id)

  if (geocoded.geocodedPlace) {
    user.pauseGeoLocation()
    locationAutoComplete.value.hide()
    search.location = geocoded.geocodedPlace

    // calculate zoom level based on the address components
    const zoomLevel = geocoded.calculateZoomLevel(geocoded.geocodedPlace.addressComponents, map.zoom)
    map.navigateTo = [geocoded.geocodedPlace.longitude, geocoded.geocodedPlace.latitude]
    map.zoom = zoomLevel

    // if mobile, show the map
    if (isMobile) ui.showMapView()
  }
}

async function getUserLocation() {
  await user.fetchGeoLocation()
  locationAutoComplete.value.hide()
  map.navigateTo = user.coordinates
  map.zoom = config.public.map.userPositionZoomLevel
}

const pt = computed<PassThrough<AutoCompletePassThroughOptions>>(() => {
  return {
    root: {
      class: 'w-full flex justify-between items-center'
    },
    container: {
      class: 'list-none cursor-text'
    },
    input: {
      class:
        'h-full text-sm text-gray-500 w-full py-1.5 placeholder-gray-500 bg-transparent outline-none selection:bg-trublue'
    },
    panel: ({ props }) => {
      return {
        class: [
          'bg-white max-h-[250px] pt-1 overflow-auto rounded-b-lg shadow-xl',
          props.suggestions?.length === 0 ? 'pb-0' : 'pb-4',
          isMobile ? 'w-9/12' : ''
        ]
      }
    },
    list: ({ props }) => {
      return {
        class: props.suggestions?.length === 0 ? 'hidden' : 'list-none m-0 max-w-[400px]'
      }
    },
    item: {
      class:
        'px-4 py-2 text-gray-500 cursor-pointer hover:bg-trublue-50 text-sm overflow-hidden text-ellipsis relative whitespace-nowrap m-0 truncate'
    },
    itemgroup: {
      class: ['m-0 px-4 pt-2 border-t border-gray-300 text-trublue-700 bg-white font-semibold text-sm', 'cursor-auto']
    },
    virtualScroller: {},
    emptyMessage: {
      class: 'px-4 text-sm text-trublue-700 font-semibold'
    },
    dropdownButton: {
      root: {
        class: 'w-full absolute inset-0 h-full bg-transparent [&>svg]:hidden'
      }
    }
  }
})
</script>
