<template>
  <div
    v-if="!!!cookieConsent"
    class="fixed bottom-0 left-0 z-20 flex items-center justify-between w-full p-4 space-x-4 text-sm text-white border-t bg-stone-900"
  >
    <div class="">
      We use cookies to enhance your browsing experience, personalise content and analyse our traffic. By clicking
      &quot;Accept&quot;, you consent to our use of cookies.<NuxtLink
        class="ml-2 underline text-experience underline-offset-2"
        aria-label="Click to read our cookie policy"
        href="https://trurating.com/privacy-cookie-policy/"
        target="_blank"
        rel="noopener noreferrer"
        >Read our cookie policy</NuxtLink
      >
    </div>
    <div class="flex flex-col items-center space-y-2 shrink-0 md:space-x-4 md:flex-row md:space-y-0">
      <UButton
        @click="acceptCookies"
        variant="outline"
        :ui="{
          rounded: 'rounded-2xl',
          font: 'font-regular'
        }"
        class="text-experience hover:text-white hover:bg-stone-950"
        aria-label="Accept cookies"
      >
        Accept Cookies
      </UButton>
      <UButton
        @click="denyCookies"
        variant="outline"
        :ui="{
          rounded: 'rounded-2xl',
          font: 'font-regular'
        }"
        class="text-experience hover:text-white hover:bg-stone-950"
        aria-label="Deny cookies"
      >
        Deny Cookies
      </UButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const { grantConsent, revokeConsent } = useGtag()
const gtm = useGtm()
const cookieConsent = useCookie<boolean | null>('sf:cookieconsent', {
  expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
})

if (cookieConsent.value === true) {
  gtm.enable(true)
  grantConsent()
}

function acceptCookies() {
  grantConsent()
  gtm.enable(true)
  cookieConsent.value = true
}

function denyCookies() {
  revokeConsent()
  gtm.enable(false)
  cookieConsent.value = null
}
</script>
