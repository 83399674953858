export const appHead = {"meta":[{"name":"viewport","content":"width=device-width,initial-scale=1,maximum-scale=1"},{"charset":"utf-8"},{"name":"description","content":"StoreFinder"},{"name":"theme-color","content":"#2BB5FD"}],"link":[],"style":[],"script":[],"noscript":[],"title":"Store Finder - TruRating","viewport":"width=device-width,initial-scale=1,maximum-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false