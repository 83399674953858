import { config } from '@fortawesome/fontawesome-svg-core'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { GeocodedResult, GeoCoderSearchResult } from '~/types'

export const useGeocodedStore = defineStore('geocoded', () => {
  const config = useRuntimeConfig()
  const search = useSearchStore()
  const user = useUserStore()
  const abort = new AbortController()
  const searchTerm = ref<string>()
  const searchTermModel = ref<string | GeoCoderSearchResult>()

  const geocodedPlace = shallowRef<GeocodedResult>()
  const queryClient = useQueryClient()

  const { data, isFetching } = useQuery({
    queryKey: ['location', 'list', searchTerm],
    queryFn: () =>
      $fetch<Array<GeoCoderSearchResult>>('/api/geocode/autocomplete', {
        query: { query: searchTerm.value, country: user.countryCode },
        signal: abort.signal
      }),
    enabled: () => searchTerm.value !== undefined
  })

  const suggestions = computed(() => data.value ?? [])
  const firstSuggestion = computed(() => data.value?.[0])

  // actions
  function clear() {
    queryClient.removeQueries({ queryKey: ['location', 'list', searchTerm.value] })
    searchTerm.value = undefined
    searchTermModel.value = undefined
    search.location = undefined
    user.useMyLocation = false
  }

  function fetchSuggestions(term: string) {
    searchTerm.value = term
  }

  async function fetchGeocodedPlace(placeId: string) {
    try {
      const detailsResponse = await $fetch<GeocodedResult>('/api/geocode/geocode', {
        query: { id: placeId },
        signal: abort.signal
      })
      const zoomLevel = calculateZoomLevel(detailsResponse.addressComponents, config.public.map.defaultZoomLevel)
      detailsResponse.zoomLevel = zoomLevel
      geocodedPlace.value = detailsResponse
    } catch (error) {
      console.log(`Error fetching geocoded place (${placeId}): ${error}`)
    }
  }

  function calculateZoomLevel(addressComponents: GeocodedResult['addressComponents'], defaultZoomLevel: number) {
    let zoomLevel = defaultZoomLevel
    if (!addressComponents) return zoomLevel

    const zoomMapping = new Map<string, number>([
      ['street_number', 18],
      ['route', 18],
      ['premise', 18],
      ['neighborhood', 14],
      ['sublocality_level_1', 12],
      ['sublocality', 12],
      ['locality', 12],
      ['political', 11],
      ['administrative_area_level_2', 10],
      ['administrative_area_level_1', 7],
      ['country', 5]
    ])

    const types = Array.from(new Set(addressComponents.flatMap(component => component.types)))

    if (types.length > 0 && zoomMapping.has(types[0])) {
      zoomLevel = zoomMapping.get(types[0]) as number
    }

    return zoomLevel
  }

  watch(
    () => search.location,
    location => {
      if (location) {
        searchTermModel.value = location.address
      }
    }
  )

  return {
    // state
    firstSuggestion,
    pending: computed(() => isFetching.value),
    geocodedPlace,
    searchTermModel,
    suggestions,

    // actions
    calculateZoomLevel,
    clear,
    fetchGeocodedPlace,
    fetchSuggestions
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGeocodedStore, import.meta.hot))
}
