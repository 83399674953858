import type { Extent } from 'ol/extent'
import { acceptHMRUpdate } from 'pinia'
import type { MapCoordinate } from '~/types'

export const useMapStore = defineStore('map', () => {
  const config = useRuntimeConfig()
  const ui = useUiStore()
  const user = useUserStore()

  const center = ref<MapCoordinate>([])
  const zoom = ref(config.public.map.defaultZoomLevel)
  const extent = ref<Extent>([])
  const navigateTo = ref<MapCoordinate>()

  const selectedFeatureId = ref<number | null>(null)
  const hoveredFeatureId = ref<number | null>(null)
  const hoverOverlayIsOutside = ref(false)

  // actions
  function initialise(mapCenter: MapCoordinate, zoomLevel: number) {
    center.value = mapCenter
    zoom.value = zoomLevel
  }

  function onRenderComplete() {
    ui.searchThisAreaButtonVisible = false
  }

  function onMoveStart() {
    user.fetchLocationFromQuery()
    ui.searchThisAreaButtonVisible = false

    // clear overlays and popups
    if (navigateTo.value) {
      selectedFeatureId.value = null
      hoveredFeatureId.value = null
    }

    if (!ui.showResultsAsMapMoves && !navigateTo.value && !selectedFeatureId.value)
      ui.searchThisAreaButtonVisible = true
  }

  function onMoveEnd(mapCenter: MapCoordinate, mapExtent: Extent, mapZoom: number) {
    center.value = mapCenter.map(c => roundCoordinate(c))
    extent.value = mapExtent.map(c => roundCoordinate(c))
    zoom.value = mapZoom
  }

  return {
    // state
    center,
    extent,
    zoom,
    hoveredFeatureId,
    hoverOverlayIsOutside,
    navigateTo,
    selectedFeatureId,

    // actions
    initialise,
    onMoveEnd,
    onMoveStart,
    onRenderComplete
  }
})

// adds HMR for pinia
if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useMapStore, import.meta.hot))
