<template>
  <div class="relative w-full h-full">
    <div id="tru-map" class="w-full h-full touch-none"></div>
    <MapPopOver
      v-if="search.selectedStore"
      v-show="ui.showMap"
      :store="search.selectedStore"
      @close="map.selectedFeatureId = null"
    />
    <MapPopOverHovered
      :store="search.hoveredStore"
      @is-outside="onHoverOverlayIsOutside"
      @close="map.hoveredFeatureId = null"
    />
  </div>
</template>

<script setup lang="ts">
import Feature, { type FeatureLike } from 'ol/Feature'
import Map from 'ol/Map'
import Overlay, { type Positioning } from 'ol/Overlay'
import View from 'ol/View'
import { defaults as defaultControls } from 'ol/control'
import { click } from 'ol/events/condition'
import { boundingExtent } from 'ol/extent'
import { Point } from 'ol/geom'
import { defaults as defaultInteractions } from 'ol/interaction'
import LinkInteraction from 'ol/interaction/Link'
import Select from 'ol/interaction/Select'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import { useGeographic } from 'ol/proj'
import type RenderFeature from 'ol/render/Feature'
import { Cluster } from 'ol/source'
import OSM from 'ol/source/OSM'
import VectorSource from 'ol/source/Vector'
import { Fill, Icon, Stroke, Style, Text } from 'ol/style'
import type { MapCoordinate } from '~/types'

useGeographic()
const config = useRuntimeConfig()
const map = useMapStore()
const ui = useUiStore()
const user = useUserStore()
const search = useSearchStore()
const geocoded = useGeocodedStore()
const gtm = useGtm()

let olMapRef: Map
const { start: startOverlayTimeout, stop: stopOverlayTimeout } = useTimeoutFn(() => {
  map.hoverOverlayIsOutside = true
  map.hoveredFeatureId = null
}, 500)
const clusterSource = new Cluster({
  distance: 40,
  source: new VectorSource({
    features: []
  })
})

const vectorClusterLayer = new VectorLayer({
  source: clusterSource
})

function onHoverOverlayIsOutside(isOutside: boolean) {
  map.hoverOverlayIsOutside = isOutside
  if (!isOutside) stopOverlayTimeout()
}

onMounted(async () => {
  try {
    await user.initialise()
  } catch (error) {
    console.log('User blocked geolocation, carry on with alternative location methods')
  }

  map.initialise(
    user.coordinates,
    user.useMyLocation
      ? config.public.map.userPositionZoomLevel
      : geocoded.geocodedPlace
        ? geocoded.geocodedPlace.zoomLevel
        : config.public.map.defaultZoomLevel
  )

  const storeHoveredOverlay = new Overlay({
    element: document.getElementById('map-popover-hovered')!
  })

  vectorClusterLayer.setStyle(f => {
    const feature = f as Feature
    const features = getFeatures(feature)
    const count = features.length
    const isCluster = features.length > 1

    feature.set('isCluster', isCluster)
    feature.set('count', count)

    if (isCluster) {
      const isFeatureWithinACluster = features.some(x => (x.getId() as number) === map.selectedFeatureId)
      const mapMarkerCluster = getMapMarker(
        isFeatureWithinACluster ? 'map_marker_cluster_selected' : 'map_marker_cluster'
      )
      mapMarkerCluster.setText(
        new Text({
          font: '600 13px "Open Sans"',
          text: count > 99 ? '99+' : count.toString(),
          fill: new Fill({ color: 'white' }),
          stroke: new Stroke({ color: 'black', width: 1 })
        })
      )
      return mapMarkerCluster
    }

    const featureId = getFeature(feature).getId() as number
    return featureId === map.selectedFeatureId ? getMapMarker('map_marker_selected') : getMapMarker('map_marker')
  })

  const selectClick = new Select({
    condition: click,
    filter: f => {
      return !isClusteredFeature(f)
    },
    style: getMapMarker('map_marker_selected')
  })
  selectClick.on('select', function (evt) {
    console.log('selectClick evt :>> ', evt)

    if (evt.selected.length > 0) {
      map.hoveredFeatureId = null

      const feature = evt.selected[0].get('features')[0] as Feature
      const id = feature.getId() as number
      map.selectedFeatureId = id

      const store = search.getStoreById(id)
      gtm?.trackEvent({
        event: 'Store Marker Selected',
        category: 'discovery',
        action: 'Select Store Marker',
        label: store.displayName
      })
    } else {
      map.selectedFeatureId = null
      map.navigateTo = undefined
    }
  })

  // map instance
  olMapRef = initMap(map.center, map.zoom)
  olMapRef.addOverlay(storeHoveredOverlay)
  olMapRef.addInteraction(selectClick)

  olMapRef.once('rendercomplete', evt => {
    map.onRenderComplete()
    search.performSearch().then(() => {
      if (search.stores.length === 0) ui.toggleResultsView()
    })
  })

  olMapRef.on('movestart', evt => {
    if (!olMapRef) return
    map.onMoveStart()
  })

  olMapRef.on('moveend', evt => {
    if (!olMapRef) return
    const view = olMapRef.getView()
    const mapCenter = view.getCenter()!
    const mapZoom = view.getZoom()!
    const mapExtent = view.calculateExtent(olMapRef.getSize())

    map.onMoveEnd(mapCenter, mapExtent, mapZoom)
    if (searchCriteria.value) {
      search.performSearch().then(() => {
        map.navigateTo = undefined
        if (search.stores.length === 0) ui.toggleResultsView()

        // if the user has selected a store, set it selected so the marker changes etc
        if (search.storeOrOutlet) map.selectedFeatureId = search.storeOrOutlet.id
      })
    }
  })

  olMapRef.on(
    'pointerdrag',
    useDebounceFn(() => {
      if (!olMapRef) return
      user.pauseGeoLocation()
    }, 500)
  )

  olMapRef.on('pointermove', evt => {
    if (evt.dragging) {
      map.hoveredFeatureId = null
      storeHoveredOverlay.setPosition(undefined)
      return
    }

    // Get all features at pixel
    const allFeatures = evt.map.getFeaturesAtPixel(evt.pixel, {
      hitTolerance: map.selectedFeatureId ? 0 : 24
    })

    // Filter out the features that are not clusters
    const features = allFeatures
      .filter(f => !isClusteredFeature(f))
      .map<Feature>(f => {
        return getFeature(f)
      })

    const hoveredFeature = features.length > 0 ? features[0] : undefined

    if (hoveredFeature) {
      map.hoverOverlayIsOutside = false
      map.hoveredFeatureId = hoveredFeature.getId() as number
      const store = search.getStoreById(map.hoveredFeatureId)
      const mapWidth = document.getElementById('tru-map')?.offsetWidth ?? 0
      const mapHeight = document.getElementById('tru-map')?.offsetHeight ?? 0
      const pixel = olMapRef.getPixelFromCoordinate([store.long, store.lat])
      let xLocation = pixel[0]
      let yLocation = pixel[1]

      let positioning: Positioning = 'top-left'
      if (xLocation + 250 > mapWidth) {
        xLocation = mapWidth - 86
        positioning = 'top-right'
      }
      if (yLocation + 132 > mapHeight) {
        yLocation = mapHeight - 26
        positioning = 'bottom-left'
      }

      const coords = olMapRef.getCoordinateFromPixel([xLocation, yLocation])
      storeHoveredOverlay.setOffset([16, -8])
      storeHoveredOverlay.setPositioning(positioning)
      storeHoveredOverlay.setPosition(coords)
    } else {
      // start timeout to hide the overlay
      map.hoverOverlayIsOutside = true
      startOverlayTimeout()
      if (!map.hoverOverlayIsOutside) stopOverlayTimeout()
    }

    // change the map to a cursor over the feature to indicate interaction
    const mapTarget = olMapRef.getTarget() as HTMLDivElement
    mapTarget.style.cursor = allFeatures.length > 0 ? 'pointer' : 'default'
  })

  olMapRef.on('click', async evt => {
    const clickedFeatures = evt.map.getFeaturesAtPixel(evt.pixel)
    if (!clickedFeatures.length) return

    // get the features within the cluster
    const features = clickedFeatures[0].get('features') as Array<RenderFeature>
    if (features.length > 1) {
      // zoom to fit the clustered features
      const extent = boundingExtent(features.map(r => r.getGeometry().getFlatCoordinates()))
      olMapRef.getView().fit(extent, { duration: 1000, padding: [100, 100, 100, 100] })
    }
  })
})

const searchCriteria = computed(() => {
  if (map.selectedFeatureId) return false
  if (map.navigateTo) return true
  if (ui.showResultsAsMapMoves) return true
  // return !!ui.showResultsAsMapMoves || !!map.navigateTo
})

// watch for changes in the store's center and zoom
watch(
  [() => map.navigateTo, () => map.zoom],
  ([navigationPoint, zoom], [oldNavigationPoint, oldZoom]) => {
    if (!olMapRef) return

    if (navigationPoint)
      olMapRef.getView().animate({
        center: navigationPoint,
        duration: 1000
      })
    if (zoom !== oldZoom)
      olMapRef.getView().animate({
        duration: 1000,
        zoom: zoom
      })
  },
  { flush: 'post' }
)

// watch for changes in the store's features
watch(
  () => [...search.stores],
  stores => {
    if (!olMapRef) return
    clusterSource.getSource()?.clear()
    const features: Feature[] = stores.map(store => {
      const feature = new Feature({
        geometry: new Point([store.long, store.lat])
      })

      feature.set('name', store.displayName)
      feature.setId(store.id)
      return feature
    })

    clusterSource.getSource()?.addFeatures(features)
  }
)

watch(
  () => map.selectedFeatureId,
  (id, prevId) => {
    if (id) {
      const store = search.getStoreById(id)
      if (store)
        olMapRef.getView().animate({
          center: [store.long, store.lat],
          duration: 500,
          zoom: map.zoom
        })
    }

    if (!id && prevId) {
      const feature = clusterSource.getSource()!.getFeatureById(prevId) as Feature
      if (feature) feature.setStyle(getMapMarker('map_marker'))
    }
  }
)

// helper functions
function initMap(center: MapCoordinate, zoom: number) {
  return new Map({
    target: document.getElementById('tru-map')!,
    layers: [
      new TileLayer({
        source: new OSM()
      }),
      vectorClusterLayer
    ],
    view: new View({
      center: center,
      zoom: zoom,
      constrainResolution: true,
      maxZoom: 18
    }),
    controls: defaultControls({
      attribution: false
    }),
    interactions: defaultInteractions().extend([new LinkInteraction({ params: ['x', 'y', 'z'] })])
  })
}

function getFeatureData<T = any>(feature: Feature, key: string) {
  return feature.get(key) as T
}

function isClusteredFeature(feature: Feature | FeatureLike) {
  return getFeatureData<boolean>(feature as Feature, 'isCluster')
}

function getFeature(feature: Feature | FeatureLike) {
  return getFeatures(feature)[0]
}

function getFeatures(feature: Feature | FeatureLike) {
  return getFeatureData<Array<Feature>>(feature as Feature, 'features')
}

function getFeatureById(id: number) {
  return clusterSource.getSource()!.getFeatureById(id) as Feature
}

function getMapMarker(marker: string) {
  const markers = ['map_marker', 'map_marker_selected', 'map_marker_cluster', 'map_marker_cluster_selected'].reduce(
    (acc: Record<string, Style>, marker) => {
      acc[marker] = new Style({
        image: new Icon({
          size: marker.startsWith('map_marker_cluster') ? [48, 48] : [22, 28],
          src: `/images/${marker}.svg`
        })
      })
      return acc
    },
    {}
  )

  return markers[marker]
}
</script>
