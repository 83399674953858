import { useQuery } from '@tanstack/vue-query'
import type { StoreFinderUrlSearchParams, GeocodedResult, SearchQuery, StoreOrOutlet, StoreResult } from '~/types'

export const useSearchStore = defineStore('search', () => {
  const abort = new AbortController()
  const geocoded = useGeocodedStore()
  const map = useMapStore()
  const ui = useUiStore()

  // state
  const storeTypeIds = ref<Array<number>>()
  const storeOrOutlet = ref<StoreOrOutlet>()
  const storeOrOutletSearchModel = ref<string | StoreOrOutlet>()
  const location = ref<GeocodedResult>()
  const results = reactive(new Map<number, StoreResult>())

  const queryName = computed(() => {
    if (storeOrOutletSearchModel.value) {
      if (typeof storeOrOutletSearchModel.value === 'string') {
        return storeOrOutletSearchModel.value
      } else {
        return storeOrOutletSearchModel.value.queryName
      }
    }
  })

  const query = computed<SearchQuery>(() => {
    return {
      storeTypeIds: storeTypeIds.value,
      name: storeOrOutlet.value?.isBrand ? null : queryName.value,
      longSw: map.extent[0],
      latSw: map.extent[1],
      longNe: map.extent[2],
      latNe: map.extent[3],
      brandId: storeOrOutlet.value?.isBrand ? storeOrOutlet.value?.id : null
    }
  })

  watchEffect(() => {
    // we need to update the url querystring parameters when the search changes
    const params = useUrlSearchParams<StoreFinderUrlSearchParams>('history')
    params.location = location.value ? location.value.id.toString() : undefined

    // if (storeTypeIds.value) {
    //   params.storetypeids = storeTypeIds.value.map(id => id.toString())
    // }
    // if (storeOrOutlet.value) {
    //   params.storeoroutlet = storeOrOutlet.value.id.toString()
    // }
  })

  // const { data: data2, isFetching } = useQuery({
  //   queryKey: ['search', query, 'list'],
  //   queryFn: () =>
  //     $fetch<Array<StoreResult>>('/api/fullsearch', {
  //       query: query.value,
  //       signal: abort.signal
  //     }),
  //   enabled: () => query.value !== undefined
  // })

  // todo: limit max zoom to 6 so can only search >= 6
  const { data, pending, execute } = useFetch(`/api/fullsearch`, {
    query: query,
    signal: abort.signal,
    immediate: false,
    watch: false
  })

  watchEffect(() => {
    if (data.value) {
      results.clear()
      for (const storeResult of data.value) {
        results.set(storeResult.id, storeResult)
      }
    }
  })
  const stores = computed(() => Array.from(results.values()))

  // getters
  const selectedStore = computed(() => {
    if (map.selectedFeatureId) {
      return results.get(map.selectedFeatureId)
    }
  })

  const hoveredStore = computed(() => {
    if (map.hoveredFeatureId) {
      return results.get(map.hoveredFeatureId)
    }
  })

  async function performSearch() {
    if (!location.value && geocoded.firstSuggestion) {
      await geocoded.fetchGeocodedPlace(geocoded.firstSuggestion.id)
      if (geocoded.geocodedPlace) {
        location.value = geocoded.geocodedPlace
        geocoded.searchTermModel = geocoded.firstSuggestion.value

        const zoomLevel = geocoded.calculateZoomLevel(geocoded.geocodedPlace.addressComponents, map.zoom)
        map.navigateTo = [geocoded.geocodedPlace.longitude, geocoded.geocodedPlace.latitude]
        map.zoom = zoomLevel
      }
    }
    console.log('storeOrOutlet', storeOrOutlet.value)
    console.log('storeOrOutletSearchModel', storeOrOutletSearchModel.value)
    console.log('performSearch', query.value)

    ui.searchThisAreaButtonVisible = false
    return execute()
  }

  function getStoreById(storeId: number) {
    return results.get(storeId)!
  }

  function $reset() {
    storeTypeIds.value = undefined
    storeOrOutlet.value = undefined
    location.value = undefined
  }

  return {
    // state
    location,
    query,
    pending,
    results,
    stores,
    storeOrOutlet,
    storeOrOutletSearchModel,
    storeTypeIds,

    // getters
    hoveredStore,
    selectedStore,

    // actions
    $reset,
    getStoreById,
    performSearch
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot))
}
