<template>
  <MultiSelect
    ref="storeTypesMultiSelect"
    v-model="search.storeTypeIds"
    placeholder="All store types"
    option-label="name"
    option-value="id"
    :options="storeTypes.storeTypes"
    :max-selected-labels="isMobile ? 1 : 2"
    :show-toggle-all="false"
    :pt="pt"
  >
    <template #footer>
      <div class="flex items-center justify-center px-6 py-4 gap-x-4">
        <UButton
          @click="apply"
          variant="outline"
          class="text-trublue-700"
          :ui="{
            rounded: 'rounded-3xl'
          }"
          >Apply</UButton
        >
        <UButton
          @click="clearSelectedStoreTypes"
          variant="outline"
          class="text-trublue-700"
          :ui="{
            rounded: 'rounded-3xl'
          }"
          >Clear</UButton
        >
      </div>
    </template>
  </MultiSelect>
</template>

<script setup lang="ts">
import type { MultiSelectPassThroughOptions } from 'primevue/multiselect'
import type { PassThrough } from 'primevue/ts-helpers'

const search = useSearchStore()
const storeTypes = useStoreTypesStore()
const storeTypesMultiSelect = ref()
const { isMobile } = useDevice()
const gtm = useGtm()

function apply() {
  storeTypesMultiSelect.value.hide()

  gtm?.trackEvent({
    event: 'Store types applied',
    category: 'search',
    action: 'User applied store types',
    label: storeTypes.storeTypes
      .filter(type => search.storeTypeIds?.includes(type.id))
      .map(type => type.name)
      .join(', ')
  })
  search.performSearch()
}

function clearSelectedStoreTypes() {
  search.storeTypeIds = undefined
  storeTypesMultiSelect.value.hide()

  gtm?.trackEvent({
    event: 'Store types cleared',
    category: 'search',
    action: 'User cleared store types'
  })
  search.performSearch()
}

const pt = computed<PassThrough<MultiSelectPassThroughOptions>>(() => {
  return {
    root: {
      class: 'inline-flex items-center h-full justify-between text-sm relative w-full rounded-l-full'
    },
    labelContainer: {
      class: ['overflow-hidden flex text-gray-500 flex-auto cursor-pointer items-center py-1.5']
    },
    label: ({ props }) => {
      const selectedCount = props.modelValue?.length ?? 0

      return {
        class: [
          'block truncate overflow-hidden whitespace-nowrap cursor-pointer text-ellipsis py-1.5',
          selectedCount < 2 && isMobile ? 'max-w-24' : ''
        ]
      }
    },
    trigger: {
      class: [
        'flex items-center justify-center shrink-0',
        'bg-transparent text-gray-500 w-12 rounded-tr-lg rounded-br-lg'
      ]
    },
    panel: {
      class: ['bg-white text-gray-500 border-0 rounded-md shadow-lg', isMobile ? 'w-9/12' : '']
    },
    wrapper: {
      class: ['max-h-[200px] overflow-auto', 'bg-white text-gray-500 border-0 rounded-lg shadow-lg']
    },
    list: {
      class: ['py-3 list-none m-0']
    },
    item: ({ context }) => ({
      class: [
        'px-4 py-2.5 flex items-center gap-x-2 justify-between cursor-pointer hover:bg-trublue-50 text-sm overflow-hidden relative whitespace-nowrap m-0'
      ]
    }),
    option: {
      class: ['order-0', 'truncate']
    },
    itemCheckbox: {
      root: 'order-1 mr-2 text-trublue text-sm',
      input: 'hidden'
    },
    closeButton: {
      class: ['hidden']
    }
  }
})
</script>
