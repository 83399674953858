import { defineStore, acceptHMRUpdate, skipHydrate } from 'pinia'

type UserIpInformation = {
  country_code: string
  country_name: string
  latitude: number
  longitude: number
}

type UserLocation = {
  type: 'pending' | 'gps' | 'ip' | 'query' | 'location'
  latitude: number
  longitude: number
}

export const useUserStore = defineStore('user', () => {
  const { isSupported, pause } = useGeolocation({ immediate: false })
  const permissionsSuppported = useSupported(() => navigator && 'permissions' in navigator)
  const onboarded = useLocalStorage('onboarded', false)
  const geocoded = useGeocodedStore()
  const search = useSearchStore()

  const countryCode = ref<string>()
  const countryName = ref<string>()
  const useMyLocation = ref(false)
  const locale = ref<UserLocation>({
    type: 'pending',
    latitude: Infinity,
    longitude: Infinity
  })
  const permissionStatus = ref<PermissionState>('prompt')

  async function initialise() {
    await fetchUserIpInformation()
    const status = await checkGeolocationPermission()
    permissionStatus.value = status

    const { hasLocation } = await fetchLocationFromQuery()
    if (!hasLocation) {
      // use geo location if permission is granted or prompted to use it
      if (status === 'granted' || status === 'prompt') await fetchGeoLocation()
    }
  }

  async function checkGeolocationPermission(): Promise<PermissionState> {
    // this is for devices that don't support the permissions API
    if (!permissionsSuppported.value) return 'prompt'

    const geoLocationPermissionStatus = await navigator.permissions.query({ name: 'geolocation' })
    geoLocationPermissionStatus.onchange = () => {
      permissionStatus.value = geoLocationPermissionStatus.state
    }

    return geoLocationPermissionStatus.state
  }

  async function fetchLocationFromQuery() {
    if (useMyLocation.value) return { hasLocation: true }

    const { query } = useRoute()
    const { x: longitude, y: latitude, location: placeId } = query

    if (longitude && latitude) {
      locale.value = {
        type: 'query',
        latitude: roundCoordinate(Number(latitude)),
        longitude: roundCoordinate(Number(longitude))
      }
      return { hasLocation: true }
    }

    if (!placeId) return { hasLocation: false }

    await geocoded.fetchGeocodedPlace(placeId.toString())
    if (!geocoded.geocodedPlace) return { hasLocation: false }

    locale.value = {
      type: 'location',
      latitude: roundCoordinate(geocoded.geocodedPlace.latitude),
      longitude: roundCoordinate(geocoded.geocodedPlace.longitude)
    }
    search.location = geocoded.geocodedPlace

    return {
      hasLocation: true
    }
  }

  async function fetchUserIpInformation() {
    const { country_code, country_name, latitude, longitude } =
      await $fetch<UserIpInformation>('https://ipapi.co/json/')

    countryCode.value = country_code
    countryName.value = country_name
    locale.value = {
      type: 'ip',
      latitude: roundCoordinate(latitude),
      longitude: roundCoordinate(longitude)
    }
  }

  function fetchGeoLocation() {
    return new Promise<void>((resolve, reject) => {
      if (!isSupported.value) reject()

      navigator.geolocation.getCurrentPosition(
        position => {
          locale.value = {
            type: 'gps',
            latitude: roundCoordinate(position.coords.latitude),
            longitude: roundCoordinate(position.coords.longitude)
          }
          useMyLocation.value = true
          permissionStatus.value = 'granted'
          resolve()
        },
        error => {
          useMyLocation.value = false
          permissionStatus.value = 'denied'
          reject(error)
        }
      )
    })
    // if (!isSupported.value) return
    // navigator.geolocation.getCurrentPosition(pos => {
    //   locale.value = {
    //     type: 'gps',
    //     longitude: roundCoordinate(pos.coords.longitude),
    //     latitude: roundCoordinate(pos.coords.latitude)
    //   }
    //   useMyLocation.value = true
    // })
  }

  function pauseGeoLocation() {
    pause()
    useMyLocation.value = false
  }

  return {
    // state
    countryCode,
    countryName,
    locale,
    isSupported,
    onboarded: skipHydrate(onboarded),
    permissionStatus,

    // getters
    coordinates: computed(() => [locale.value.longitude, locale.value.latitude]),
    useMyLocation,

    // actions
    fetchGeoLocation,
    pauseGeoLocation,
    fetchLocationFromQuery,
    initialise
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
