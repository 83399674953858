<template>
  <div>
    <div class="flex items-center">
      <div
        :class="{
          'w-[96px] h-[16px]': size === 'small',
          'w-[116px] h-5 mr-2': size === 'regular'
        }"
        class="relative shrink-0 text-purple"
      >
        <div class="absolute top-0 left-0 flex h-[14px] space-x-1 md:h-5">
          <img
            v-for="n in 5"
            :key="n"
            src="@/assets/images/star_outline.svg"
            alt="Star"
            :class="starClass"
            class="inline-block shrink-0"
          />
        </div>
        <div
          :style="{ width: `${percentage}%` }"
          :class="starClass"
          class="absolute top-0 left-0 flex space-x-1 overflow-hidden"
        >
          <img
            v-for="n in 5"
            :key="n"
            src="@/assets/images/star.svg"
            alt="Star"
            :class="starClass"
            class="inline-block shrink-0"
          />
        </div>
      </div>
      <div :class="variant" class="ml-1">
        <span :class="{ 'md:text-xl': size === 'regular' }" class="font-bold">{{ $n(rating) }}</span
        ><span class="text-xs"> /5</span>
      </div>
    </div>
    <div class="mt-2 text-sm font-bold text-purple">
      {{ $n(totalRatings) }}
      <span v-if="size === 'regular'" class="text-xs font-normal">verified ratings</span>
      <span v-if="size === 'small'" class="text-xs font-normal text-gray-500">ratings</span>
    </div>
  </div>
</template>

<script setup lang="ts">
type Variant = 'purple' | 'blue'
type Size = 'regular' | 'small'

const props = withDefaults(
  defineProps<{
    rating: number
    totalRatings: number
    variant?: Variant
    size?: Size
  }>(),
  {
    variant: 'purple',
    size: 'regular'
  }
)
const percentage = (props.rating / 5) * 100
const variant = computed(() => ({
  'text-purple': props.variant === 'purple',
  'text-trublue': props.variant === 'blue'
}))

const starClass = computed(() => ({
  'w-4 h-[16px]': props.size === 'small',
  'w-5 h-5': props.size === 'regular'
}))
</script>
