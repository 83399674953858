<template>
  <div class="flex flex-col h-screen max-h-full antialiased bg-white">
    <Header />
    <main class="flex h-full overflow-hidden md:flex-row">
      <StoreList />
      <div class="relative w-full h-full">
        <OpenLayersMap />
        <UiOnboarding />
        <UButton
          v-show="ui.searchThisAreaButtonVisible"
          @click="search.performSearch()"
          size="xs"
          :ui="{
            base: 'absolute top-8 right-4 bg-trublue-700',
            rounded: 'rounded-3xl'
          }"
        >
          Search this area
        </UButton>
      </div>
    </main>
    <Footer />
    <CookieConsent />
  </div>
</template>
<script setup lang="ts">
const search = useSearchStore()
const ui = useUiStore()
</script>
