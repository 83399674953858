export default defineAppConfig({
  ui: {
    primary: 'trublue',
    toggle: {
      default: {
        color: 'trublue'
      }
    },
    button: {
      default: {
        color: 'trublue'
      }
    },
    checkbox: {
      default: {
        color: 'trublue'
      },
      label: 'font-medium text-gray-500'
    },
    selectMenu: {
      input: 'text-gray-500',
      container: 'z-30',
      option: {
        color: 'text-gray-500'
      }
    },
    input: {
      icon: { trailing: { pointer: '' } },
      placeholder: 'placeholder-gray-500 dark:placeholder-gray-600'
    },
    skeleton: {
      background: 'bg-gray-200'
    }

    // alert: {
    //   variant: {
    //     solid: 'bg-{color} text-white'
    //   }
    // }
  }
})
