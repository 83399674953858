import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'
import { CaptureConsole, HttpClient } from '@sentry/integrations'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()
  const router = useRouter()

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentry.dsn,
    environment: config.public.sentry.environment,
    debug: config.public.sentry.environment !== 'production',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay(),
      new HttpClient(),
      new CaptureConsole({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['error']
      })
    ],
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    trackComponents: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1
  })

  return {
    provide: {
      sentry: Sentry
    }
  }
})
