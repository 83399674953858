<template>
  <div
    v-show="!hideOnboaring"
    style="display: none"
    class="fixed top-0 bottom-0 left-0 right-0 z-30 flex items-center justify-center bg-neutral-950/60"
  >
    <UCard
      :ui="{
        strategy: 'override',
        base: 'p-9 max-w-2xl shadow-xl',
        divide: '',
        body: {
          base: 'text-purple flex flex-col md:flex-row justify-between relative',
          padding: ''
        }
      }"
    >
      <div class="w-full sm:w-1/2">
        <div class="flex justify-center sm:hidden">
          <img class="w-auto h-20 mb-5" src="/images/onboarding_marker_mobile.svg" alt="Onboarding marker" />
        </div>
        <p class="text-lg text-center sm:text-left">
          <span class="font-bold text-trublue">Explore the brands</span> that want you to know what their customers
          really think.
        </p>
        <p class="mt-4 text-center sm:text-left">
          Discover all stores near you, refine by store type or find a retailer by name.<br /><br />
          It's all just a quick search away...
        </p>
        <UButton
          class="justify-center w-full font-bold sm:w-32 text-trublue-700 mt-9"
          @click="setOnboardingComplete"
          variant="outline"
          size="lg"
          :ui="{
            rounded: 'rounded-3xl'
          }"
        >
          OK, Got it
        </UButton>
      </div>
      <div class="justify-end hidden w-1/2 sm:flex">
        <img class="w-auto" src="/images/onboarding_marker.svg" alt="Onboarding marker" />
      </div>
    </UCard>
  </div>
</template>

<script setup lang="ts">
const user = useUserStore()
const route = useRoute()
const { location } = route.query

const hideOnboaring = computed(() => {
  if (location) return true
  return user.onboarded
})

function setOnboardingComplete() {
  user.onboarded = true
}
</script>
