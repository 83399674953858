export default defineNuxtPlugin(async nuxtApp => {
  nuxtApp.hooks.hook('app:beforeMount', async ctx => {
    // force the colour theme to be light in case older users have local storage set to dark
    useLocalStorage('nuxt-color-mode', 'light')
  })

  nuxtApp.hooks.hook('app:mounted', async ctx => {})
  //   nuxtApp.hooks.hook('app:rendered', () => {
  //     queryClient.prefetchQuery({
  //       queryKey: ['store-types', 'list'],
  //       queryFn: fetchStoreTypes
  //     })
  //   })
  // }
})
